
import Pagination from "@/components/shared/Pagination.vue";
import GatewayModal from "@/components/gateways/GatewayModal.vue";
import ElementList, {
  ListColumn,
} from "@/components/shared/layout/ElementList.vue";
import gatewayTypeToUserText from "@/components/gateways/GatewayUtil";
import { Component, Vue } from "vue-property-decorator";
import { GatewayMerchant } from "@fundraisingbureauet/admin-typescript-client/models/GatewayMerchant";
import { GatewayType } from "@fundraisingbureauet/admin-typescript-client/models/GatewayType";
import { GatewaysApi } from "@fundraisingbureauet/admin-typescript-client/apis/GatewaysApi";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";

@Component({
  components: {
    Pagination,
    GatewayModal,
    ElementList,
  },
})
export default class Gateways extends Vue {
  gatewayMerchants: GatewayMerchant[] = [];
  newGatewayMerchant: GatewayMerchant | null = null;
  currentGatewayMerchant?: GatewayMerchant | null = null;
  columns: ListColumn<GatewayMerchant>[] = [
    {
      header: "Merchant name",
      key: "merchantName",
      field: "merchantName",
    },
    {
      header: "Merchant ID",
      key: "gatewayMerchantId",
      field: "gatewayMerchantId",
    },
    {
      header: "Created",
      key: "createdTs",
      field: "createdTs",
    },
  ];
  currentGatewayType = "";
  searchString = "";
  page = 1;
  listErrored = false;
  listLoading = false;

  /**
   * Initialise
   */
  created() {
    this.getAvailableGateways();
    this.getGatewayConfigurations();
  }

  /**
   * Get and store available gateway types
   */
  private getAvailableGateways() {
    new GatewaysApi(AuthInterceptor.Instance).getAvailableGateways().subscribe(
      (response) => {
        this.$root.$data.availableGateways = response;
      },
      (error) => {
        eventHub.$emit("httpError", error);
      }
    );
  }

  /**
   * Get and store gateway configurations
   */
  private getGatewayConfigurations() {
    new GatewaysApi(AuthInterceptor.Instance)
      .getGatewayConfigurations()
      .subscribe(
        (response) => {
          const gatewayConfigurations = [];
          for (const [key, value] of Object.entries(response)) {
            gatewayConfigurations.push(value);
          }
          this.$root.$data.configurableGateways = gatewayConfigurations;
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  /**
   * Get gateway merchants by selected gateway type
   */
  private getGatewayMerchants() {
    this.listLoading = true;
    this.gatewayMerchants = [];

    new GatewaysApi(AuthInterceptor.Instance)
      .getGatewayMerchants({
        gatewayType: this.currentGatewayType.toUpperCase() as GatewayType, // convert eNum to uppercase string
      })
      .subscribe(
        (response) => {
          this.gatewayMerchants = response;
        },
        (error) => {
          eventHub.$emit("httpError", error);
          this.listErrored = false;
        },
        () => {
          this.listLoading = false;
        }
      );
  }

  private createGatewayMerchant() {
    this.newGatewayMerchant = {} as GatewayMerchant;
  }

  private updateGatewayMerchant(gatewayMerchant: GatewayMerchant) {
    this.currentGatewayMerchant = gatewayMerchant;
  }

  private closeModal(updated: boolean) {
    this.newGatewayMerchant = null;
    this.currentGatewayMerchant = null;
  }

  private filterGatewayMerchant(item: GatewayMerchant, filter: string) {
    if (filter === "") {
      return item;
    } else {
      return (
        item.merchantName.includes(filter) ||
        item.gatewayMerchantId.toLowerCase().includes(filter)
      );
    }
  }

  private gatewayCreated(gatewayMerchant: GatewayMerchant) {
    this.newGatewayMerchant = null;
    this.currentGatewayMerchant = gatewayMerchant;
    this.getGatewayMerchants();
  }

  private gatewayUpdated(gatewayMerchant: GatewayMerchant) {
    this.newGatewayMerchant = null;
    this.currentGatewayMerchant = gatewayMerchant;
    this.getGatewayMerchants();
  }

  /**
   * Convert to readable friendly text
   */
  private getGatewayType(gatewayType: GatewayType) {
    return gatewayTypeToUserText(gatewayType);
  }
}
